import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-jhipster';

interface IOurMenuProps {
  currentLocale: string;
}

export const OurMenu = ({ currentLocale }: IOurMenuProps) => {
  // Map locales to specific PDF URLs
  const pdfMap = {
    'en': 'content/menus/menu_en.pdf',
    'es': 'content/menus/menu_es.pdf',
    'pt-br': 'content/menus/menu_pt-br.pdf',
    'zh-tw': 'content/menus/menu_zh-tw.pdf'
  };

  // Get the PDF URL for the current locale
  const pdfUrl = pdfMap[currentLocale] || '/menu_en.pdf';

  // Function to open the PDF in a new tab
  const openMenuPdf = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <NavItem>
      <NavLink tag={Link} to="/" onClick={openMenuPdf} className="d-flex align-items-center">
        <FontAwesomeIcon icon="book" />
        <span>
          <Translate contentKey="global.menu.ourMenu">Our Menu</Translate>
        </span>
      </NavLink>
    </NavItem>
  );
};
