import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, increment, update, child } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYlsYX_B3tnuCD3TRZiUUfGftHJvALCUQ",
  authDomain: "yankee-food-314db.firebaseapp.com",
  databaseURL: "https://yankee-food-314db-default-rtdb.firebaseio.com",
  projectId: "yankee-food-314db",
  storageBucket: "yankee-food-314db.firebasestorage.app",
  messagingSenderId: "402875441929",
  appId: "1:402875441929:web:1a97ad51a15ad6ee29a9d8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const VisitorCounter = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const dbRef = ref(database, 'Visitors');

    // Fetch the current count
    get(child(dbRef, "counter"))
        .then(
          (snapshot) => {
            if (snapshot.exists()) {
              setCount(snapshot.val());
            } else {
              setCount(0); // Initialize count if it doesn't exist
            }
          }
        );

    // Increment the count
    update(dbRef, { counter: increment(1) });
  }, []);

  return (
    <div style={{ textAlign: 'right', width: '100%' }}>
      {`000000${count}`.slice(-6)}
    </div>
  );
};

export default VisitorCounter;
