import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';
import VisitorCounter from './visitor-counter';

const Footer = () => (
  <div className="footer">
     <Row className="footer-ribbon"></Row>
     <Row>
        <Col md="6">
          <p>
            <Translate contentKey="footer">Your footer</Translate>
          </p>
        </Col>
        <Col md="6">
            <VisitorCounter/>
        </Col>
    </Row>
  </div>
);

export default Footer;
