// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  height: 50px;
  margin: 0;
  padding: 0;
}

.footer-ribbon {
  background-image: url("../../content/images/ribbon.png");
  background-repeat: repeat-x;
  background-size: 99px 12px; /* Set background size exactly to the image size */
  height: 12px; /* Ribbon height */
  width: 100vw; /* Full width of the screen */
  margin-top: 1px; /* Space below the logo */
  background-position: 0 0; /* Ensure it's aligned to the top-left */
  left: 0;
  right: 0;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/footer/footer.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,SAAA;EACA,UAAA;AACF;;AAEA;EACE,wDAAA;EACA,2BAAA;EACA,0BAAA,EAAA,kDAAA;EACA,YAAA,EAAA,kBAAA;EACA,YAAA,EAAA,6BAAA;EACA,eAAA,EAAA,yBAAA;EACA,wBAAA,EAAA,wCAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;AACF","sourcesContent":[".footer {\n  height: 50px;\n  margin: 0;\n  padding: 0;\n}\n\n.footer-ribbon {\n  background-image: url('../../content/images/ribbon.png');\n  background-repeat: repeat-x;\n  background-size: 99px 12px;  /* Set background size exactly to the image size */\n  height: 12px;  /* Ribbon height */\n  width: 100vw;   /* Full width of the screen */\n  margin-top: 1px; /* Space below the logo */\n  background-position: 0 0; /* Ensure it's aligned to the top-left */\n  left: 0;\n  right: 0;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
