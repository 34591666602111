import './header.scss';

import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand } from './header-components';
import { OurMenu } from './our-menu';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { useAppDispatch } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { Row, Col } from 'reactstrap';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useAppDispatch();

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div id="app-header" className="header-container">
      {renderDevRibbon()}
      <LoadingBar className="loading-bar" />
      <Row className="align-items-center no-gutters">
        <Col>
          <img src="../../content/images/logo-top.png" alt="Logo" className="logo-top" />
        </Col>
        <Col>
          <Navbar data-cy="navbar" light expand="md" className="bg-light">
{/*            <NavbarToggler aria-label="Menu" onClick={toggleMenu} /> */}
            <NavbarToggler aria-label="Toggle navigation" onClick={toggleMenu}>
              <span className="custom-toggler-icon">≡</span>
            </NavbarToggler>

            <Collapse isOpen={menuOpen} navbar>
              <Nav id="header-tabs" className="ms-auto" navbar>
                <OurMenu currentLocale={props.currentLocale} />
                <Home />
                {props.isAuthenticated && <EntitiesMenu />}
                {props.isAuthenticated && props.isAdmin && (
                  <AdminMenu showOpenAPI={props.isOpenAPIEnabled} showDatabase={!props.isInProduction} />
                )}
                <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                {props.isAuthenticated && <AccountMenu isAuthenticated={props.isAuthenticated} />}
              </Nav>
            </Collapse>
          </Navbar>
        </Col>
      </Row>
      <Row className="header-ribbon"></Row>
    </div>
  );
};

export default Header;
